import { isDefined } from "@clipboard-health/util-ts";
import {
  MANDATORY_BREAK_DURATION_IN_MINUTES,
  MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES,
} from "@src/app/hcpShifts/constants";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { Shift } from "@src/lib/interface";
import { addMinutes, differenceInMinutes, parseISO } from "date-fns";
import moment from "moment-timezone";

const rateFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function getShiftTitleString({
  shiftStart,
  shift,
  shiftStateData,
}: {
  shiftStart?: moment.MomentInput;
  shift?: Shift;
  shiftStateData?: ShiftStateData;
}): string {
  const now = moment();
  const dayEnd = moment().endOf("day");
  const dayEndTomorrow = moment().add(1, "day").endOf("day");
  const timeSheetExists = shift?.timecard?.files?.length || shift?.timecardNotAvailable?.reason;

  if (shiftStateData?.metadata.isShiftOver) {
    return "";
  }
  if (now.isAfter(shiftStart) && dayEnd.isAfter(shiftStart) && timeSheetExists) {
    return "";
  }
  if (now.isAfter(shiftStart)) {
    return "Shift In Progress";
  }
  if (now.isBefore(shiftStart) && dayEnd.isAfter(shiftStart)) {
    return "Today";
  }
  if (dayEnd.isBefore(shiftStart) && dayEndTomorrow.isAfter(shiftStart)) {
    return "Tomorrow";
  }

  return "";
}

export function formatRate(rate?: number): string {
  if (rate === undefined) {
    return "";
  }
  if (Math.floor(rate) !== rate) {
    return rateFormatter.format(rate);
  }
  return rate.toString();
}

export function getShiftBreakDuration(shift: Shift): number {
  return shift.lunchInOut?.start
    ? differenceInMinutes(new Date(), parseISO(shift.lunchInOut.start))
    : 0;
}

export function calculateShiftMandatoryBreakTimes(
  shift?: Shift
): { start: Date; end: Date } | undefined {
  const workStart = shift?.clockInOut?.start ?? shift?.start;
  const workEnd = shift?.clockInOut?.end ?? shift?.end;
  const lunchStart = shift?.lunchInOut?.start;
  const lunchEnd = shift?.lunchInOut?.end;

  if (
    isDefined(workStart) &&
    isDefined(workEnd) &&
    (!isDefined(lunchStart) || !isDefined(lunchEnd))
  ) {
    const durationInMinutes = differenceInMinutes(parseISO(workEnd), parseISO(workStart));

    // introduce FF for min shift time for mandatory break
    if (durationInMinutes > MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES) {
      // todo add exact middle minutes
      const breakStart = addMinutes(parseISO(workStart), durationInMinutes / 2);
      return {
        start: breakStart,
        end: addMinutes(breakStart, MANDATORY_BREAK_DURATION_IN_MINUTES),
      };
    }
  }

  return undefined;
}

// Returns a shift completion percentage between 0 and 1, or undefined if the shift hasn't started
// Based on the clock in time (or shift start time if clocked in early)
export function getShiftCompletionPercentage(shift: Shift): number | undefined {
  if (!shift.clockInOut?.start || !shift.start || !shift.end) {
    return undefined;
  }

  const currentTime = new Date();
  const clockInTime = parseISO(shift.clockInOut.start);
  const shiftStartTime = parseISO(shift.start);
  const shiftEndTime = parseISO(shift.end);

  const minutesSinceClockedIn = differenceInMinutes(currentTime, clockInTime);
  const minutesSinceShiftStarted = differenceInMinutes(currentTime, shiftStartTime);

  const minutesInShift = Math.min(minutesSinceClockedIn, minutesSinceShiftStarted);
  const minutesLeftInShift = differenceInMinutes(shiftEndTime, currentTime);
  const totalMinutesInShift = minutesInShift + minutesLeftInShift;

  if (minutesInShift < 0 || totalMinutesInShift === 0) {
    return 0;
  }

  if (minutesInShift >= totalMinutesInShift) {
    return 1;
  }

  return minutesInShift / totalMinutesInShift;
}
